@import '~antd/dist/antd.css';

body {
  margin: auto;
  padding: 40px 20px;
  max-width: 1000px;
  width: 100%;

  word-break: keep-all;
}
